@import url("https://fonts.googleapis.com/css2?family=Space+Mono&family=Roboto:wght@300;400;700&display=swap");

body {
  font-family: "Roboto", sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f9; /* Light background */
  color: #333; /* Dark text */
  line-height: 1.6; /* Increase line height for readability */
}

header {
  background-color: #ffffff;
  color: #333;
  padding: 1rem;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

header h1 {
  margin: 0;
  font-family: "Space Mono", monospace;
  font-size: 2rem;
  color: #007bff;
}

nav a {
  color: #333;
  margin: 0 1rem;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

nav a:hover {
  color: #007bff;
}

main {
  padding: 2rem;
  background-color: #ffffff;
  margin: 1rem auto;
  border-radius: 10px;
  max-width: 900px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.section {
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
  transition: transform 0.3s;
}

.section:hover {
  transform: translateY(-5px);
}

footer {
  background-color: #ffffff;
  color: #333;
  text-align: center;
  padding: 1rem;
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.1);
}

h1,
h2 {
  font-family: "Space Mono", monospace;
  color: #007bff;
}

h2 {
  border-bottom: 2px solid #007bff;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
}

p,
ul {
  line-height: 1.8;
  margin: 0 0 1.5rem 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  background-color: #f9f9f9;
  margin: 0.5rem 0;
  padding: 0.5rem;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease-in-out;
}

ul li:hover {
  background-color: #e9ecef;
}

a {
  color: #007bff;
}

a:hover {
  text-decoration: underline;
}
